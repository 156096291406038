/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --loader-background-color: white;
    --loader-transparent-background-color: rgba(255, 255, 255, .6);
}

.Loader {
    background-color: var(--loader-background-color);

    &_isFixed {
        position: fixed;
        inset: 0;
    }

    &_isTransparent {
        background-color: var(--loader-transparent-background-color);
    }
}