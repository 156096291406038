/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --input-type-number-width: 60px;
    --product-card-add-to-cart-padding: 1.2rem;
    --product-card-background: transparent;
    --product-card-price-font-size: 2rem;
    --product-card-price-line-height: 1.3;
    --product-card-box-shadow: 0 0 5px rgba(0,0,0,0.1);
    --product-card-box-shadow-hover: 0 3px 6px -4px rgba(0, 0, 0, .1),
    0 6px 16px rgba(0, 0, 0, .1),
    0 9px 28px 8px rgba(0, 0, 0, .1);
    --product-card-magazine-qty-color: white;
    --product-card-magazine-qty-background-color: var(--primary-base-color);
    --product-card-name-font-size: 1.8rem;
    --product-card-saas-product-title-block-min-height: 9.6rem;
    --product-card-price-wrapper-margin-bottom: 1.5rem;

    @include desktop {
        --product-card-padding: 2rem;
        --product-card-price-wrapper-margin-bottom: 0;
    }

    @include mobile {
        --product-card-padding: 1rem;
        --product-card-price-font-size: 1.6rem;
    }
}

.ProductCard {
    @include product-card-regular;
    
    padding-inline-start: var(--product-card-padding);
    box-shadow: var(--product-card-box-shadow);
    padding: var(--product-card-padding);
    align-content: space-between;
    margin: 0;
    height: auto;

    &-Name {
        white-space: normal;
    }

    @include desktop {
        height: 100%;
        align-items: end;
    }

    @include mobile {
        &-Picture {
            padding-bottom: 200%;
        }
    }

    &_listItemView_column {
        .SaasProductTitleBlock {
            > * {
                justify-self: center;
            }
        }
    }

    &_listItemView_row {
        .SaasProductTitleBlock {
            > * {
                @include mobile {
                    justify-self: center;
                }

                .SaasSliderWidget_totalItems_2 & {
                    justify-self: center;
                }
            }
        }
    }

    .SaasProductTitleBlock {
        margin-bottom: 1rem;
        min-height: var(--product-card-saas-product-title-block-min-height);

        h2 {
            &,
            .ProductListWidget & {
                padding: 0;
                font-style: normal;
                font-size: 1.6rem;
                line-height: var(--product-card-name-line-height);
                width: 100%;
                max-width: 100%;
                max-height: calc(3 * var(--product-card-name-line-height));
                overflow: hidden;
                margin: 0;
                text-transform: none; //This should be a setting

                > span {
                    color: inherit;

                    @include mobile {
                        white-space: initial;
                    }
                }
            }
        }
    }

    &-Price {
        &.ProductPrice {
            margin-bottom: 0;
        }
    }

    &_listItemView_column {
        justify-content: space-between;

        @include desktop {
            .SaasProductTitleBlock {
                &.ProductCard {
                    &-Name {
                        h2 {
                            &.ProductActions {
                                &-Title {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &_listItemView_row {
        @include desktop {
            flex-direction: row;
            flex-flow: row;
        }

        @include mobile {
            flex-direction: column;
            flex-flow: column;
        }

        column-gap: 2rem;
        
        > a {
            width: 40%;

            @include mobile {
                .SaasSliderWidget_totalItems_1 & {
                    width: 100%;
                }
            }
        }

        .ProductCard {
            &-AddToWrapper {
                @include desktop {
                    justify-content: flex-start;
                }
    
                @include mobile {
                    flex-direction: row;
                }
            }

            &-Footer {
                @include desktop {
                    justify-content: start;

                    .SaasSliderWidget_totalItems_2 & {
                        justify-content: center;
                    }
                }
            }
        }

        .SaasProductTitleBlock {
            align-items: flex-start;
            text-align: left;
        }

        .SaasSliderWidget_totalItems_2 & {
            @include product-card-regular;

            .ProductCard-PriceWrapper {
                text-align: center;
            }

            .SaasProductTitleBlock {
                @include product-title-regular;
            }
        }
    }

    &-Content {
        padding: 0;
        width: 100%;

        .ProductCard_listItemView_row & {
            max-width: 18rem;

            @include desktop {
                justify-content: space-between;
                text-align: left;
            }

            @include mobile {
                text-align: center;
                margin-top:auto;
            }
        }
    }

    &-Content {
        .SaasSliderWidget_totalItems_1 .ProductCard_listItemView_row & {
            max-width: none;
        }
    }

    &-Name {
        font-size: var(--product-card-name-font-size);
        line-height: var(--product-card-price-line-height);
        margin-bottom: 3rem;

        @include desktop {
            white-space: unset;
            text-overflow: unset;
        }
    }

    &-PriceWrapper {
        margin-bottom: var(--product-card-price-wrapper-margin-bottom);
        text-align: center;

        .ProductCard_listItemView_row & {
            text-align: left;
        }
    }

    &-Price {
        font-size: var(--product-card-price-font-size);
    }

    &-AddToWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-direction: column;

        @include mobile {
            flex-direction: row;
        }

        @include after-tablet-landscape {
            flex-direction: row;
        }
    }

    &-Qty.Field {

        @include mobile {
            margin: 0;
        }

        @include desktop {
            margin: 0 1rem 0 0;
        }

        .SaasInputSelectField {
            height: 100%;
            

            @include mobile {
                max-width: 5rem;
            }

            &-Input {
                text-align: left;
                height: 100%;
                min-width: auto;

                @include desktop {
                    text-indent: 2rem;
                }
                
                @include mobile {
                    text-indent: 1rem;
                    max-width: 5rem;
                }
            }
        }
    }

    &-SelectQty {
        margin-top: 0;

        .FieldSelect {
            min-width: 5rem;

            &,
            &-Select {
                height: 100%;
            }

            &-Select {
                padding: .5rem 2rem .5rem 1rem;
            }

            &-Option {
                padding-right: 1.8rem;
                padding-left: 0;
            }

            &::after {
                right: 1rem;
            }
        }
    }

    &-Attributes {
        display: flex;
        flex-direction: column;
    }

    &-Attribute {
        display: flex;
        justify-content: center;
        font-size: 1.3rem;
        margin-bottom: 3px;
    }

    &-AttributeLabel {
        font-weight: bold;
    }

    &-ValueLabel {
        padding: 0 6px 0 3px;
    }

    &-MagazineQty {
        position: absolute;
        padding: .8rem 1.4rem;
        background: var(--product-card-magazine-qty-background-color);
        color: var(--product-card-magazine-qty-color);
        z-index: 1;
        min-width: 50px;
        font-size: 2.2rem;
    }

    &-AddToCart {
        margin-left: 1rem;
        flex-grow: 2;
        white-space: nowrap;
        min-width: auto;
        padding: var(--product-card-add-to-cart-padding);

        @include mobile {
            margin-left: 0;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}
