/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --success-page-background-color: #F9F9F9;
    --success-page-border: 1px solid #DDDDDD;
    --success-page-item-content-background-color: #FFFFFF;
}

.Checkout {
    &_isSuccess {
        background: var(--success-page-background-color);
        padding: 4rem 0;

        @include mobile {
            padding: 0;
        }

        .Checkout {
            &-Wrapper {
                grid-template-columns: auto;
                --content-wrapper-width: 1460px;
            }
        }

        .Checkout-Header {
            display: none;
        }

        .SuccessContainer {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 3rem;

            &-Item {
                box-shadow: 0px 0px 5px rgba(0,0,0,0.15);

                &--content {
                    background-color: var(--success-page-item-content-background-color);
                    padding: 2rem;

                    h1 {
                        font-size: 3rem;
                        font-weight: 500;
                        margin-bottom: 1rem;
                    }

                    ul {
                        margin: 0 -2rem -1rem -2rem;

                        li {
                            display: flex;
                            align-items: center;
                            border-bottom: var(--success-page-border);
                            padding: 1rem 2rem 1rem 1rem;

                            &:first-of-type {
                                border-top: var(--success-page-border);
                            }

                            > span {
                                &.icon {
                                    width: 4.5rem;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .fa {
                                        font-size: 3rem;
                                    }
                                }

                                &:not(.icon) {
                                    width: calc(100% - 4.5rem);
                                    padding-left: 1rem;

                                    a {
                                        text-decoration: underline;

                                        &:hover {
                                            text-decoration: none;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &--image {
                        width: 100%;
                    }
                }
            }

            &-ButtonRow {
                .Button {
                    margin-top: 1rem;
                    margin-right: 1rem;
                    border: none;
                    border-radius: 5px;
                }
            }
        }

        @include mobile {
            .SuccessContainer {
                grid-template-columns: 1fr;
                gap: 2rem;

                &-ButtonRow {
                    display: flex;
                    flex-direction: column;

                    .Button {
                        margin-right: 0;
                    }
                }
            }
        }

        @include desktop {
            .SuccessContainer {
                &-Item {
                    &--image {
                        display: block;
    
                        .Image {
                            padding-bottom: 0;
                            height: 100%;
                            width: 100%;
                        }
    
                        img {
                            min-width: 100%;
                            min-height: 100%;
                            object-fit: cover;
                            max-height: 100%;
                        }
                    }
                }
            }
        }
    }
}