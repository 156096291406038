/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../pwasaas/src/style/abstract/variables';
@import '../../../../../pwasaas/src/style/abstract/media';
@import '../../../../../pwasaas/src/style/abstract/button';
@import '../../../../../pwasaas/src/style/abstract/loader';
@import '../../../../../pwasaas/src/style/abstract/icons';
@import '../../../../../pwasaas/src/style/abstract/image';
@import '../../../../../pwasaas/src/style/abstract/parts';

:root {
    --header-menu-link-font-weight: normal;
    --header-menu-main-item-margin-right-after-tablet: 2rem;
    
    --saas-header-usps-total-height: 0px;
    
    @include mobile {
        --saas-header-nav-padding: 1rem 0;
        --saas-header-nav-display: flex;
    }

    @include desktop {
        --saas-header-nav-links-max-width: 27rem;
    }
}

.SaasHeader {
    &-MyAccount {
        @include mobile {
            margin-right: 0.4rem;
        }
    }

    &-NavLogo {
        @include mobile {
            margin: 0 0 0 4.6rem;
        }
    }

    &-Bottom {
        @include mobile {
            border: 1px solid #e6e6e6; 
        }
    }
}