/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

:root {
  --product-card-has-product-labels-figure-padding-top: 2.4rem;
  --product-card-label-group-children-padding: 1.5rem;

  --product-label-font-size: 1.2rem;
  --product-label-text-font-weight: 700;
  --product-label-text-color: #000;
  --product-label-text-transform: uppercase;
  --product-label-margin: 0 0.5rem 0.5rem 0;
  --product-label-padding: 0 1rem;
  --product-label-border: 1px solid transparent;
  --product-label-border-radius: 0;
  --product-label-background-color: #ececec;
  --product-label-outline: 0;
  --product-label-width: auto;
  --product-label-height: 3rem;
  --product-label-group-flex-wrap: no-wrap;
  --product-label-group-flex-direction: column;
  --product-label-group-top: 1rem;
  --product-label-group-bottom: 0;
  --product-label-group-left: 1rem;
  --product-label-group-right: 0;
  --product-label-group-z-index: 1;
  --product-label-image-position: absolute;
  --product-label-image-top: 0;
  --product-label-image-left: 0;
  --product-label-image-width: 10rem;
  --product-label-image-height: 10rem;

  // Discount Label
  --product-discount-label-background-color: #ececec;
  --product-discount-label-text-color: var(--product-label-text-color);
  --product-discount-label-margin: 0;
  --product-discount-label-percentage-text-color: var(--product-discount-label-text-color);
  --product-discount-label-percentage-font-size: var(--product-discount-label-font-size);
  --product-discount-label-border: var(--product-label-border);
  --product-discount-label-text-transform: uppercase;
  --product-discount-label-border-radius: 50%;
  --product-discount-label-number-font-weight: 700;
  --product-discount-label-text-color: #000;

  @include desktop {
    --product-discount-label-group-top: 1rem;
    --product-discount-label-group-right: 1rem;
    --product-discount-label-width: 5rem;
    --product-discount-label-height: 5rem;
    --product-discount-label-font-size: .8rem;
    --product-discount-label-number-font-size: 1.8rem;
  }

  @include mobile {
    --product-discount-label-group-top: 1.5rem;
    --product-discount-label-group-right: 1.5rem;
    --product-discount-label-width: 5.5rem;
    --product-discount-label-height: 5.5rem;
    --product-discount-label-font-size: 1rem;
    --product-discount-label-number-font-size: 1.6rem;
  }
}

.SaasProductLabels {
    &-Wrapper {
      width: 100%;
      min-height: 6rem;
    }

    &-ProductLabelGroup {
      position: absolute;
      display: flex;
      flex-direction: var(--product-label-group-flex-direction);
      flex-wrap: var(--product-label-group-flex-wrap);
      z-index: var(--product-label-group-z-index);
      flex-wrap: wrap;

      &_position_top-left {
        top: var(--product-label-group-top);
        left: var(--product-label-group-left);
        z-index: calc(var(--product-label-group-z-index) + 1);
      }

      &_position_top-right {
        top: var(--product-discount-label-group-top);
        right: var(--product-discount-label-group-right);
      }

      &_position_bottom-left {
        bottom: var(--product-label-group-bottom);
        left: var(--product-label-group-left);
      }

      &_position_bottom-right {
        bottom: var(--product-label-group-bottom);
        right: var(--product-label-group-right);
      }

      &_image {
        --product-label-group-right: 1rem;
        width: var(--product-label-image-width);
        height: var(--product-label-image-height);
        right: var(--product-label-group-right);

        .SaasProductLabels{
          &-ProductDiscountLabel {
            display: none;
          }
        }

        @include mobile {
          --product-label-group-right: 0;
        }
      }
    }

    &-ProductLabel {
      margin: var(--product-label-margin);
      padding: var(--product-label-padding);
      border: var(--product-label-border);
      border-radius: var(--product-label-border-radius);
      background-color: var(--product-label-background-color);
      outline: var(--product-label-outline);
      width: var(--product-label-width);
      height: var(--product-label-height);
      line-height: 1;
      text-align: left;
      text-transform: var(--product-label-text-transform);
      display: flex;
      align-items: center;
    }

    &-ProductLabelText {
      font-size: var(--product-label-font-size);
      font-weight: var(--product-label-text-font-weight);
      color: inherit; // Parent color is from component(admin value)
      position: relative;
      z-index: 20;
    }

    &-ProductLabelImage {
      position: absolute;
      top: var(--product-label-image-top);
      left: var(--product-label-image-left);
      width: var(--product-label-image-width) !important;
      height: var(--product-label-image-height) !important;
      z-index: 2;

      .Image-Image {
        [dir="ltr"] & {
          left: unset;
          right: 0;
        }
      } 
    }

    &-ProductDiscountLabel {
      margin: var(--product-discount-label-margin);
      border: var(--product-discount-label-border);
      border-radius: var(--product-discount-label-border-radius);
      background-color: var(--product-discount-label-background-color);
      width: var(--product-discount-label-width);
      height: var(--product-discount-label-height);
      font-size: var(--product-discount-label-font-size);
      text-transform: var(--product-discount-label-text-transform);
      color:var(--product-discount-label-text-color);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 1;
    }

    &-ProductDiscountLabelText {
      font-size: var(--product-discount-label-font-size);
      color: var(--product-discount-label-text-color);
      position: relative;
      z-index: 2;
    }

    &-ProductDiscountLabelPercentage {
      font-size: var(--product-discount-label-number-font-size);
      color: var(--product-discount-label-text-color);
      font-weight: var(--product-discount-label-number-font-weight);
    }
}

.ProductCard {
  --product-label-group-flex-direction: row;
  --product-label-image-width: 8rem;
  --product-label-image-height: 5.5rem;

  &_hasProductLabels {
    .ProductCard-Content {
        @include desktop {
          padding-top: var(--product-card-label-group-children-padding);
        }
    }

    .ProductCard-Figure {
      padding-top: var(--product-card-has-product-labels-figure-padding-top);
    }
  }
  
  @include mobile {
    .SaasProductLabels {
      &-ProductLabelGroup_position_top-right {
        --product-label-group-right: 1.5rem;
      }
    }
  }
}

.ProductGallery {
  .SaasProductLabels {
    &-ProductLabel {
      margin-right: auto;

      &_hasShape {
        &:nth-of-type(1n+2) {
          padding: 0.5rem 1rem;
  
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.BlogPostPage {
  &-RelatedProducts {
      .SaasProductLabels {
          &-ProductLabelGroup {
              &_position_top-left {

                  @include desktop {
                      left: 30%;
                  }

                  @include mobile {
                      left: 0;
                  }
              }
          }

          &-ProductLabel {
              &_hasShape {
                  &:nth-of-type(1n+2) {
                      @include mobile{
                          padding: var(--product-label-padding);;
                      }
                  }
              }
          }
      }
  }
}