/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.ProductReviews {
    &-Wrapper {
        @include desktop {
            padding: 36px 0;
        }
    }

    .ProductReviews {
        &-Button {
            position: relative;
            margin: 0 0 0 auto;
        }
    }

    @include mobile {
        &-Popup_isVisible {
            // Show only store notice and nav at top, so the whole review popup is visible (also on a small phone like iPhone SE)
            inset: calc(var(--store-notice-height) + var(--saas-header-nav-height)) 0 0;
            width: auto;
            height: auto;

            // Make header visible on mobile, so you can click away the popup
            .Popup {
                &-Header {
                    display:block;
                }
            }
        }
    }
}