/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --field-message-is-info-message-color: #272323;
    --field-message-is-info-message-font-weight: bold;
}

.Field {
    &-Message {
        &_isInfo {
            color: var(--field-message-is-info-message-color);
            font-weight: var(--field-message-is-info-message-font-weight);
        }
    }
}