/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/image';
@import '../../../style/abstract/parts';

.ProductSectionNavigation {
    &-ItemReviews {
        &_hasTwoColumns {
            @include desktop {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 4rem;
            }

            > * {
                &:not(:last-child) {
                    @include mobile {
                        margin-bottom: 2rem;
                    }
                }
            }

            .ProductReviews {
                @include desktop {
                    min-height: 420px;
                    max-height: 560px;
                    overflow-y: scroll;
                }
            }

            .ProductReviewList {
                @include desktop {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}