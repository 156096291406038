/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.SearchItem {
    &-OrderListItem {
        --button-padding: 5px 15px;

        .Button {
            white-space: nowrap;
            font-size: 1.1rem;
            line-height: inherit;
            align-self: center
        }

        .SearchItem-Content {
            align-items: flex-start;
        }
    }

    &-Wrapper {
        &_isOrderList {
            grid-template-columns: auto 6rem calc(100% - 22rem);
        }
    }

    &-Title {
        &_isLoaded {
            width: 248px;
        }
    }
}
