/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/image';
@import '../../../style/abstract/parts';

:root {
    --saas-checkout-form-address-info-background: var(--checkout-totals-background-color);
    --saas-checkout-form-address-info-border: 1px solid var(--input-border-color);
    --saas-checkout-form-address-info-padding: var(--checkout-totals-padding);
}

.SaasCheckoutForm {
    &-AddressInfo {
        background-color: var(--saas-checkout-form-address-info-background);
        border: var(--saas-checkout-form-address-info-border);
        margin-top: 2rem;
        padding: var(--saas-checkout-form-address-info-padding);

        li:last-child {
            margin-bottom: 0;
        }
    }

    &-CustomEditButton {
        color: var(--primary-dark-color);
        display: inline-block;
        margin-left: .5rem;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &-Field {
        &_name_postcode,
        &_name_street1 {
            &.Field_isValid {
                &:after {
                    color: var(--primary-success-color);
                    content: '\2713';
                    display: block;
                    font-size: 20px;
                    position: absolute;
                    right: 10px;
                    top: 32px;
                }
            }
        }
    }
}