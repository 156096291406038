/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
  --field-select-border-color: var(--input-border-color);
  --field-select-input-font-size: 14px;
  --field-select-options-width: 5rem;
  --field-select-options-background-color: #fff;
  --field-select-options-max-height: 14rem;
  --field-select-options-border-color: var(--field-select-border-color);
  --field-select-option-font-size: 1.4rem;
  --field-select-option-background-hover: var(--select-option-hover-background);
  --field-select-option-line-height: 2.7rem;
  --input-type-number-height: 34.1px;
  
}

.SaasInputSelectField {
    --input-type-number-width: 60px;
    display: inline-flex;
    height: 100%;

    &-Options {
      bottom: 4rem;
      position: absolute;
      border: 1px solid transparent;
      left: 0;
      width: 100%;
      background-color: var(--field-select-options-background-color);
      max-height: 0;
      overflow-y: scroll;
      transition: 200ms max-height;
      border-bottom: 0;
 
      &_isExpanded {
        max-height: var(--field-select-options-max-height);
        border-color: var(--input-border-color);
      }
    }

    &-Input {
      text-align: center;
      padding-right: 0;
      padding-left: 0;
      border: 1px solid var(--input-border-color);
      font-size: var(--field-select-input-font-size);
      height: 100%;
    }

    &-Option {
      line-height: var(--field-select-option-line-height);
      text-align: center;
      font-size: var(--field-select-option-font-size);
      cursor: pointer;
      color: #000;

      &:hover {
        background: var(--field-select-option-background-hover);
      }
    }

  &_isSelect {
    &::after {
      position: absolute;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%);
      content: '';
      background: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjYXJldC1kb3duIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2FyZXQtZG93biBmYS13LTEwIiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMyMCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTMxLjMgMTkyaDI1Ny4zYzE3LjggMCAyNi43IDIxLjUgMTQuMSAzNC4xTDE3NC4xIDM1NC44Yy03LjggNy44LTIwLjUgNy44LTI4LjMgMEwxNy4yIDIyNi4xQzQuNiAyMTMuNSAxMy41IDE5MiAzMS4zIDE5MnoiPjwvcGF0aD48L3N2Zz4=');
      background-repeat: no-repeat;
      background-size: 0.7rem;
      background-position: center;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      z-index: 1;
    }  
  }
}

.ProductCard_listItemView_column {
  .SaasInputSelectField-Input {
    @include mobile-l {
      width: var(--input-type-number-width);
    }
  }
}

.OrderListItem {
  &-QuantityWrapper {
    .SaasInputSelectField {
      &-Options_isExpanded {
        --field-select-options-max-height: 10rem;
      }
    }
  }
}