/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/image';
@import '../../../style/abstract/parts';

.Field {
    &-InputWrapper {
        input {
            width: 100%;
        }

        .Field-Message {
            margin-top: 0;
        }
    }

    &-MultiplyButton {
        align-self: flex-end;
        justify-self: start;

        @include mobile {
            margin: 2rem 0 1rem 0;
        }
    }
}