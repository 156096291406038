/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/image';
@import '../../../style/abstract/parts';

.ProductPage {
    &_hasThreeColumns {
        .ProductPage-Wrapper {
            --product-gallery-grid-order: 1;
            --product-actions-grid-order: 0;
            grid-template-columns: var(--product-gallery-max-width) var(--product-actions-col-3-max-width) var(--product-attribute-icons-max-width);
    
            @include mobile {
                grid-template-columns: 100%;
            }
        }
    }
}