/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/image';
@import '../../../style/abstract/parts';

.PageHeader {
    &_type_sales-promotion-page {
        .PageHeader-Content {
            max-width: none;

            @include mobile {
                display: none;
            }
        }

        .PageHeader-ContentText {
            font-size: 2.6rem;
            color: white;
            margin-left: calc(var(--sales-promotion-page-sidebar-max-width) + var(--sales-promotion-page-grid-gap));
        }

        .PageHeader-Image {
            @include bg-image-gradient-fill;
        }
    }
}