/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.CheckoutAddressBook {
    .SaasCheckoutForm {
        .FieldForm {
            &-Fields {
                grid-template-columns: repeat(6, 1fr);
            }
        }
    }
}

.SaasCheckoutForm {
    .FieldForm-Fields {
        display: grid;
        grid-column-gap: 2rem;
    }

    .Field:not(.SaasCheckoutForm-Field) {
        grid-column: span 6;
    }

    &-Field {
        grid-column: span 6;

        &_width_50 {
            grid-column: span 3;
        }

        &_width_33 {
            grid-column: span 2;
        }
    }

    .Field {
        &_type_hidden {
            display: none;
        }
    }
}
