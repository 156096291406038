/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/image';
@import '../../../style/abstract/parts';

.CheckoutBilling {
    &-Promotion {
        display: flex;
        margin-bottom: 1rem;
        font-size: 1.4rem;
    }

    &-PromotionCheckbox {
        margin-top: 0;
        margin-bottom: 0;
    }

    &-PromotionLink {
        color: var(--link-color);

        &:hover {
            text-decoration: underline;
        }
    }
}