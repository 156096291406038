/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.AddToCart {
    width: fit-content;
    padding-left: var(--button-padding);
    padding-right: var(--button-padding);

    @include mobile {
        width: 100%;
        min-width: auto;
        padding: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}