/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
  --product-information-heading-font-size: 2rem;
  --product-information-heading-font-weight: 700;
  --product-information-description-font-size: 1.6rem;
  --product-information-description-margin: 1.2rem 0 0 0;

  @include mobile {
    --product-information-heading-font-size: 1.6rem;
    --product-information-heading-text-transform: uppercase;
    --product-information-heading-font-weight: 600;
    --product-information-description-font-size: 1.4rem;
    --product-information-description-margin: 0;
  }
}

.ProductInformation {
    &-Heading {
      font-size: var(--product-information-heading-font-size);
      font-weight: var(--product-information-heading-font-weight);
      text-transform: var(--product-information-heading-text-transform);
    }

    &-Description {
      font-size: var(--product-information-description-font-size);
      margin: var(--product-information-description-margin);

      ul {
        li {
          list-style-type: disc;
        }
      }
    }

    &-AttributeItemLabel {
      margin-bottom: 0;
      font-size: var(--product-information-description-font-size);
      font-weight: var(--product-information-heading-font-weight);
    }

    &-AttributeItemValue {
      font-size: var(--product-information-description-font-size);
    }
}