/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --price-color: #{$black};
    --price-with-discount-color: var(--primary-base-color);
    --price-discount-color: #808080;
    --price-font-size: 1.4rem;
    --old-price-font-size: 1.4rem;
    --product-card-price-font-size: 1.6rem;
    --product-card-old-price-font-size: 1.6rem;

    @include desktop {
        --price-font-size: 2rem;
        --old-price-font-size: 2rem;
        --product-card-price-font-size: 2rem;
        --product-card-old-price-font-size: 2rem;
    }

    @include mobile {
        --price-font-size: 1.8rem;
        --old-price-font-size: 1.8rem;
        --product-card-price-font-size: 1.8rem;
        --product-card-old-price-font-size: 1.8rem;
    }
}

.ProductPrice {
    line-height: var(--price-font-size);
    min-height: auto;

    @include desktop {
        line-height: 3.5rem;
    }

    &.ProductActions-Price {
        font-size: var(--price-font-size);
    }

    del {
        font-size: var(--old-price-font-size);
    }

    ins {
        font-size: var(--price-font-size);
    }

    &-HighPrice {
        margin-left: 0;

        @include desktop {
            margin-left: 0.4rem;
        }
    }

    &-PriceValue {
        color: var(--price-with-discount-color);
    }

    &.ProductCard-Price {
        line-height: var(--product-card-price-font-size);

        .ProductPrice {
            &-MinMax {
                color: var(--primary-base-color);
                
                + .ProductPrice-Price {
                    .ProductPrice-PriceValue {
                        color: var(--price-color);
                        font-weight: 500;
                        font-size: var(--product-card-price-font-size);
                    }
                }
            }
        }
    }

    .ProductCard & {
        &-Price {
            display: inline;
        }
    }    
}