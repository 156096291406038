/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.StoreSwitcher {
    @include mobile {
        --input-padding: 0;
        margin-bottom: 0;
        border-bottom: 0;
        
        .ChevronIcon {
            display: none;
        }

        &-StoreList {
            right: -10px;
        }
    }

    @include desktop {
        margin-inline-start: 0;
    }

    &-Title {

        img {
            width: 32px;
            height: auto;
            margin-right: .8rem;

            @include mobile {
                width: 24px;
                margin-right: 0;
            }
        }

        svg {
            transition: 0.15s ease-in-out;
        }
    }
}