/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

.Field {
    &-SubLabel {
        white-space: unset !important;
        margin-left: 0.5rem;
        font-weight: 400;
    }

    &_type_checkbox {
        label:first-of-type {
            @include mobile {
                display: grid;
                max-width: none;
                grid-template-columns: 30px 1fr;
            }
        }
    }

    [type='checkbox'] + .input-control {
        border: 1px solid var(--option-border-color) !important;
    }

    [type='checkbox']:checked + .input-control {
        background-color: var(--primary-base-color);
        
        &:after {
            --checkmark-color: #fff !important;
        }
    }
    
    &_hasError {
        select {
            border: 1px solid var(--primary-error-color);
        }
    }
}

.CategoryFilterOverlay {
    &-Attributes {
        .Field {
            &-CheckboxLabel {
                span {
                    display: inline;
                }
            }
        }
    }
}