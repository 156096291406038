/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

.ProductStock {
    &-Icon {
        margin-inline: .5rem;

        &_type_in_stock {
            color: var(--primary-success-color);
        }

        &_type_out_of_stock {
            color: var(--primary-error-color);
        }
    }
}

.ProductCardStock {
    width: 100%;
}
