/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

.ProductAttributeIcons {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    &-Item {
      width: 50%;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-ItemImage {
      max-height: 8rem;
      height: 100%;
      width: auto;
      margin-bottom: 1.5rem;
    }

    &-ItemLabel {
      font-size: 1.4rem;
      color: #272323;
      margin-bottom: 0;
      text-align: center;

      @include mobile {
        min-height: 50px;
      }
    }
}