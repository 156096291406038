/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/image';
@import '../../../style/abstract/parts';

:root {
    --saas-header-menu-bottom-background: var(--bottom-menu-background-color);
    --saas-header-menu-bottom-color: var(--bottom-menu-color);

    @include desktop {
        @at-root .hasBottomMenu {
            --saas-header-nav-menu-padding-left: var(--content-wrapper-padding-inline);
            --saas-header-menu-main-item-height: 5rem;
            --saas-header-nav-menu-padding-y: 1rem;
            --saas-header-nav-menu-height:
                calc(
                    var(--saas-header-menu-main-item-height)
                    + calc(var(--saas-header-nav-menu-padding-y) * 2)
                );

            /* Add menu item height to wrapper height */
            --saas-header-wrapper-height:
                calc(
                    var(--saas-header-bottom-height)
                    + var(--saas-header-currency-switcher-height)
                    + var(--saas-header-nav-height)
                    + var(--saas-header-usps-total-height)
                    + var(--saas-header-menu-main-item-height)
                );
        }
    }
}

.SaasHeader {
    &-Nav {
        &Menu {
            &_isMenuPositionBottom {

                @include desktop {
                    position: relative;
                    grid-template-columns: none;
                    max-width: var(--content-wrapper-width);
                    margin: 0 auto;
                }
            }
        }
    }

    &-MenuBottom {
        background-color: var(--saas-header-menu-bottom-background);
        padding-left: var(--saas-bottom-header-padding-x);
        color: var(--saas-header-menu-bottom-color);
    }
}
