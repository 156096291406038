/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

@import '@scandipwa/scandipwa/src/component/ProductCard/ProductCard.style.scss';

.ProductCompleteOrder {
    @include desktop {
        grid-row-start: 1;
        grid-row-end: 4;
        grid-column-start: 2;
        grid-column-end: 3;
    }
}

.CompleteOrder {
    &-Wrapper {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0px;
        padding-bottom: 40px;
    }
    
    &-Content {
        h2 {
            margin: 0;
            background-color: #EEE;
            border: 1px solid #DDD;
            border-bottom: none;
            display: flex;
            align-items: center;

            @include desktop {
                padding: 0 2rem;
                min-height: 5rem;
            }

            @include mobile {
                padding: 0 1rem;
                min-height: 4rem;
            }
        }
    }
}
